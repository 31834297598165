import { createSlice } from "@reduxjs/toolkit";
import { ProjectType } from "projectus";
import { Banner } from "banner";
type DataReducerType = {
  project: ProjectType[];
  banner: Banner[];
};

const initialState: DataReducerType = {
  project: [],
  banner: [],
};
const dataReducer = createSlice({
  name: "data",
  initialState,
  reducers: {
    SetProject: (state, action) => {
      state.project = action.payload;
    },
    SetBanner: (state, action) => {
      state.banner = action.payload;
    },
  },
});

export const { SetProject, SetBanner } = dataReducer.actions;

export default dataReducer.reducer;
