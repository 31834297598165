import React, { useCallback, useEffect, useState } from "react";
import About from "../About";
import { UserAgentType } from "common";
import { get, query, ref } from "firebase/database";
import { db } from "../../../core/firebase";
import { ResdiencyType } from "resdiency";
type Props = {
  userAgent: UserAgentType;
};
const AboutContainer = ({ userAgent }: Props) => {
  const [currentIdx, setCurrentIdx] = useState<null | number>(null);
  const [regidency, setregidency] = useState<ResdiencyType[]>([]);
  const SectionOpen = useCallback(
    (idx: number) => {
      setCurrentIdx((prev) => (prev === idx ? null : idx));
    },
    [currentIdx]
  );
  const GetRegidency = useCallback(async () => {
    const staticData = await get(query(ref(db, "static")));

    let ids: string[] = [];
    staticData.forEach((item) => {
      const id = item.val();
      ids.push(id);
    });

    const getAbout = await Promise.all(
      ids.map(async (id) => {
        const data = await get(query(ref(db, "regidency/" + id)));
        return data.val();
      })
    );
    setregidency(getAbout);

    // SetResidency(arr);
    // if (regidencyData.exists()) {
    //   setregidency(Object.values(regidencyData.val()));
    // }
  }, []);
  useEffect(() => {
    GetRegidency();

    return () => {};
  }, []);

  return (
    <About
      currentIdx={currentIdx}
      userAgent={userAgent}
      SectionOpen={SectionOpen}
      regidency={regidency}
    />
  );
};

export default AboutContainer;
