import { UserAgentType } from "common";
import "./css/index.css";
import React from "react";
import { ResdiencyType } from "resdiency";
import { Link } from "react-router-dom";
import { encrypto } from "../../lib/lib";
type Props = {
  data: ResdiencyType;
  userAgent: UserAgentType;
};
const AuthorImgCard = ({ userAgent, data }: Props) => {
  const key = encrypto(data.list[0].timestamp.toString());
  const ids = encrypto(String(data.id));

  return (
    <Link
      className="author-img-card"
      to={`/Residency?time=${encodeURIComponent(key)}&id=${encodeURIComponent(
        ids
      )}`}
      state={data}
    >
      {data.list && (
        <img src={data.list[0].resize || data.list[0].img} alt="author-img" />
      )}
      <div className="background-gradient" />
      <div className="author-button-wrapper">
        <p className="author-name">{data.name}</p>
        <div className="arrow-btn">
          <img src="/assets/about/arrow.svg" alt="arrow-btn" />
        </div>
      </div>
    </Link>
  );
};

export default AuthorImgCard;
