import React from "react";
import "./css/index.css";

type Props = {
  label: string;
  name: string;
  value: string;
  type: string;
  maxLength: number | undefined;
  UpdateData: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const ContactusInput = ({
  label,
  name,
  type,
  value,
  maxLength,
  UpdateData,
}: Props) => {
  return (
    <div className="contactus-input-wrapper">
      <label className="contactus-label" htmlFor={name}>
        {label}
      </label>
      <input
        type={type}
        className="contactus-input"
        placeholder={"내용을 입력해주세요"}
        name={name}
        value={value}
        maxLength={maxLength}
        onChange={UpdateData}
      />
    </div>
  );
};

export default ContactusInput;
