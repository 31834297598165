import React, { useEffect, useState } from "react";
import ProjectDetail from "../ProjectDetail";
import { UserAgentType } from "common";
import { useLocation, useParams } from "react-router-dom";
import { ProjectType } from "projectus";
import { get, ref } from "firebase/database";
import { db } from "../../../core/firebase";
type Props = {
  userAgent: UserAgentType;
};
const ProjectDetailContainer = ({ userAgent }: Props) => {
  const { state } = useLocation();
  const { id } = useParams();
  const [data, setData] = useState<ProjectType | null>(null);
  useEffect(() => {
    if (state) {
      setData(state);
    } else {
      get(ref(db, "project/" + id)).then((res) => {
        if (res.exists()) {
          setData(res.val());
        }
      });
    }
    return () => {};
  }, [state, id]);
  if (data) {
    return <ProjectDetail userAgent={userAgent} data={data} />;
  }
  return <></>;
};

export default ProjectDetailContainer;
