import React, { useCallback, useContext, useEffect, useState } from "react";
import Project from "../Project";
import { UserAgentType } from "common";
import { ProjectType } from "projectus";
import {
  UNSAFE_NavigationContext,
  useNavigate,
  useNavigationType,
} from "react-router-dom";
import { child, get, ref } from "firebase/database";
import { db } from "../../../core/firebase";
import { useDispatch, useSelector } from "react-redux";
import { SetBanner, SetProject } from "../../../store/data/data";
import { RootState } from "../../../store";
import { Banner } from "banner";
import useIsBackNavigation from "../../../hooks/UseIsBackNavigation";
type Props = {
  userAgent: UserAgentType;
};
const ProjectContainer = ({ userAgent }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isBackNavigation = useIsBackNavigation();
  const project = useSelector<RootState, ProjectType[]>(
    (state) => state.data.project
  );
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [SearchList, setSearchList] = useState<ProjectType[] | undefined>(
    undefined
  );
  const [banner, setBanner] = useState<Banner[]>([]);
  const ProjectData = async () => {
    const dbRef = ref(db);
    try {
      const snapshot = await get(child(dbRef, "project"));
      if (snapshot.exists()) {
        const fetchedData = snapshot.val();

        const projectArray = Object.keys(fetchedData)
          .map((key) => ({
            id: key,
            ...fetchedData[key],
          }))
          .sort((a, b) => b.period.start - a.period.start);
        return projectArray;
      } else {
        console.log("데이터가 존재하지 않습니다.");
        return [];
      }
    } catch (error) {
      console.error("데이터 가져오기 오류:", error);
      return [];
    }
  };

  const ProjectNavigate = useCallback(
    (data: ProjectType) => {
      const scrollY = document.getElementById("root")?.scrollTop;
      localStorage.setItem("scrollY", String(scrollY));
      localStorage.setItem("page", String(currentPage));
      if (SearchList) {
        localStorage.setItem("search", JSON.stringify(SearchList));
      }
      navigate(`/project/${data.id}`, {
        state: data,
      });
    },
    [currentPage, SearchList]
  );

  const SearchProject = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const formData = new FormData(e.target as HTMLFormElement); // form 요소에서 FormData 생성
      const data = Object.fromEntries(formData.entries()); // 객체로 변환
      const filt = project.slice().filter((item) => {
        return item.title.includes(data.search as string);
      });
      setSearchList(filt);
    },
    [project]
  );

  useEffect(() => {
    const getProjects = async () => {
      const data = await ProjectData();
      dispatch(SetProject(data));
      const filt = data.filter((item) => {
        const currentTime = Date.now();
        return (
          item.period.start <= currentTime && item.period.finish >= currentTime
        );
      });
      let arr: any[] = [];
      if (filt.length > 0) {
        arr = filt;
      } else {
        arr = [data[0]];
      }
      console.log(arr);
      arr = arr.map(({ title, subject, period, mainimg, id }) => {
        return {
          title,
          subject,
          period,
          img: mainimg,
          url: `/project/${id}`,
          type: "Project",
        };
      });
      setBanner(arr);
    };

    getProjects();
  }, []);

  useEffect(() => {
    if (isBackNavigation) {
      const scrollY = localStorage.getItem("scrollY");
      const page = localStorage.getItem("page");
      document.getElementById("root")?.scrollTo(0, Number(scrollY));
      setCurrentPage(Number(page));
      const search = localStorage.getItem("search");
      if (search) {
        setSearchList(JSON.parse(search));
      }
    }
    return () => {};
  }, [isBackNavigation]);

  return (
    <Project
      userAgent={userAgent}
      totalItems={SearchList ? SearchList.length : project.length}
      data={SearchList || project}
      currentPage={currentPage}
      ProjectNavigate={ProjectNavigate}
      setCurrentPage={setCurrentPage}
      banner={banner}
      SearchProject={SearchProject}
    />
  );
};

export default ProjectContainer;
