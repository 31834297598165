import React from "react";
import "./css/index.css";
type Props = {
  itemsPerPage: number;
  currentPage: number;
  visiblePages: number[];
  totalPages: number;
  PageChange: (page: number) => void;
};
const PageSelector = ({
  itemsPerPage,
  totalPages,
  visiblePages,
  currentPage,
  PageChange,
}: Props) => {
  return (
    <div className="page-selector">
      <button
        onClick={() => PageChange(currentPage - 1)}
        className="arrow-btn"
        disabled={currentPage === 1}
      >
        <img
          src="/assets/common/leftarrow.png"
          srcSet={`
                        /assets/common/leftarrow@2x.png 2x,
                        /assets/common/leftarrow@3x.png 3x
                    `}
          alt="left-arrow"
        />
      </button>
      {visiblePages.map((page) => (
        <button
          key={page}
          className="page-selector-btn"
          onClick={() => PageChange(page)}
          style={{
            backgroundColor: currentPage === page ? "black" : "",
            color: currentPage === page ? "white" : "black",
          }}
        >
          {page}
        </button>
      ))}
      <button
        onClick={() => PageChange(currentPage + 1)}
        className="arrow-btn"
        disabled={currentPage === totalPages}
      >
        <img
          src="/assets/common/rightarrow.png"
          srcSet={`
                        /assets/common/rightarrow@2x.png 2x,
                        /assets/common/rightarrow@3x.png 3x,
                    `}
          alt="right-arrow"
        />
      </button>
    </div>
  );
};

export default PageSelector;
