import "./css/index.css";
import { formatDate } from "../../../lib/lib";
import { Banner } from "banner";

type Props = {
  currentIndex: number;
  data: Banner[];
  NextBtn: () => void;
  PrevBtn: () => void;
};

const SliderBanner = ({ currentIndex, NextBtn, PrevBtn, data }: Props) => {
  return (
    <div className="banner">
      {data.length > 1 && (
        <button onClick={PrevBtn} className="arrow-btn prev-btn">
          <img
            src="/assets/home/pc/prev.png"
            srcSet="
                /assets/home/pc/prev@2x.png 2x,
                /assets/home/pc/prev@3x.png 3x,
            "
            alt="prev-btn"
          />
        </button>
      )}
      <div className="banner-img-wrapper">
        {data.map(({ img, title, subject, period, url, type }, idx) => {
          return (
            <a
              className={`banner-slide ${currentIndex === idx && "active"}`}
              key={idx}
              href={url ? url : undefined}
              // target={type === "Project" ? undefined : "_blank"}
              // rel="noopener noreferrer"
            >
              <div className="banner-img-box">
                <img className="banner-img" src={img} alt="" />
                <div className="background-gradient" />
                <div className="content-wrapper">
                  <p className="banner-project fahkwang-text">{type}</p>
                  <p className="banner-title">{title}</p>
                  <p className="banner-subtitle">{subject}</p>
                  <p className="banner-date">
                    {`${formatDate(period.start)} ~ ${formatDate(
                      period.finish
                    )}`}
                  </p>
                </div>
              </div>
            </a>
          );
        })}
      </div>
      {data.length > 1 && (
        <button onClick={NextBtn} className="arrow-btn next-btn">
          <img
            src="/assets/home/pc/next.png"
            srcSet="
                /assets/home/pc/next@2x.png 2x,
                /assets/home/pc/next@3x.png 3x,
            "
            alt="next-btn"
          />
        </button>
      )}
    </div>
  );
};

export default SliderBanner;
