import { useLocation, useParams } from "react-router-dom";
import NewsDetail from "../NewsDetail";
import { useEffect, useState } from "react";
import { get, ref } from "firebase/database";
import { db } from "../../../core/firebase";
import { NewsType } from "news";

const NewsDetailContainer = () => {
  const { state } = useLocation();
  const { id } = useParams();
  const [data, setData] = useState<NewsType | null>(null);

  useEffect(() => {
    if (state) {
      setData(state);
    } else {
      get(ref(db, "notice/" + id)).then((res) => {
        if (res.exists()) {
          setData(res.val());
        }
      });
    }
    return () => {};
  }, [state, id]);
  if (data) {
    return (
      <NewsDetail
        date={data.timestamp}
        title={data.title}
        img={data.img}
        content={data.content}
      />
    );
  }
  return <></>;
};

export default NewsDetailContainer;
