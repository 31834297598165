import { useEffect, useState } from "react";
import { useNavigationType } from "react-router-dom";

const useIsBackNavigation = () => {
  const navigationType = useNavigationType();
  const [isBackNavigation, setIsBackNavigation] = useState(false);

  useEffect(() => {
    if (
      navigationType === "POP" &&
      sessionStorage.getItem("isReload") !== "true"
    ) {
      setIsBackNavigation(true); // 뒤로 가기
    } else {
      setIsBackNavigation(false); // 새로고침 또는 PUSH
    }
  }, [navigationType]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      sessionStorage.setItem("isReload", "true");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      sessionStorage.removeItem("isReload");
    };
  }, []);

  return isBackNavigation;
};

export default useIsBackNavigation;
