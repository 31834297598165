import { configureStore } from "@reduxjs/toolkit";
import configReducer from "./config/config";
import dataReducer from "./data/data";
const store = configureStore({
  reducer: {
    config: configReducer,
    data: dataReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
